import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import ShearLogo from '../../assets/images/Shear-Logo.png';
import { Button, Divider, TextField, TextareaAutosize } from '@mui/material';
import ShareImage from '../../assets/images/share.png';
import { sendMails } from '../../helper/api/user';
import { useSnackbar } from 'notistack';

function ThankYouAndShare() {
  const [emails, setEmails] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleSendInvitation = async () => {
    const toArray = emails.split(',');
    console.log(toArray);

    await sendMails({to: toArray, message: 'Please enjoy your life on earthshepards.com'}, enqueueSnackbar);
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#91D36E', marginTop: 12 }}>
        <Box sx={{ textAlign: 'center', padding: 5 }}>
          <Typography sx={{ color: '#1a202c', fontWeight: 'bold', fontSize: '28px', fontFamily: 'Montserrat'}}>
            Thank You and Welcome to the Flock!
          </Typography>
        </Box>
      </Box>
      <Box>
        <Container maxWidth="md" sx={{padding: 5}}>
          <Box sx={{ display: 'flex', padding: 2}}>
            <CheckIcon sx={{ color: '#09A20C'}}/>
            <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 2}}>
              You are earning a new style of Reward, a LIVE Reward!
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', padding: 2}}>
            <CheckIcon sx={{ color: '#09A20C'}}/>
            <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 2}}>
              Until now, most rewards are DEAD; they do nothing for you except allow you to exchange (if the rules stay the same!)
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', padding: 2}}>
            <CheckIcon sx={{ color: '#09A20C'}}/>
            <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 2}}>
              Earth Shepard's Shear Rewards allows you to earn interest, use it as collateral, and purchase stuff, especially stuff vetted to be better for the earth.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', padding: 2}}>
            <CheckIcon sx={{ color: '#09A20C'}}/>
            <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 2}}>
              They can go up and down in value and are traded on the markets
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: '#6cb2fa' }}>
        <Box sx={{ textAlign: 'center', padding: 5, display: { xs: 'block', md: 'flex'}, textAlign: 'center', justifyContent: 'center' }}>
          <img src={ShareImage} alt="share" width={37} />
          <Typography sx={{ color: '#1a202c', fontWeight: 'bold', fontSize: '28px', paddingLeft: 3, fontFamily: 'Montserrat'}}>
            Share with Friends and Family
          </Typography>
        </Box>
      </Box>
      <Box>
        <Container maxWidth="md" sx={{padding: 5, display: 'flex', flexDirection: 'column'}}>
          <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 7, paddingRight: 7}}>
            If any of your friends or family might want to experience LIVE Rewards with you, enter their email addresses below. We’ll send them a note from you asking them to join. Thanks for the referral!
          </Typography>
          <TextField
            placeholder="Separate multiple email addresses with commas"
            multiline
            minRows={3}
            maxRows={5}
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            sx={{paddingLeft: 7, paddingRight: 7, paddingTop: 3}}
          />
          <Box sx={{ textAlign: 'center', paddingTop: 4}}>
            <Button size='large' variant='contained' onClick={handleSendInvitation} sx={{ marginTop: 1, width: 200, fontWeight: 'bold'}} >
              Send Invitations
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
}
export default ThankYouAndShare;