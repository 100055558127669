import { Box, Button, ImageList, ImageListItem, Input, Paper, Table, TextField, Typography } from '@mui/material';
import react, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import EmailCheck from '../../assets/images/graphic-3d-email-check.png';
import { checkEmailFormat } from '../../helper/utils';
import { join, register } from '../../helper/api/user';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

const Confirmed = () => {

    return (
        <>
            {/* <Header />
            <Footer /> */}
            <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#eff6ff', height: '100vh'}}>
                <Box sx={{ width: {xs: '100%', lg: '720px'}, mt: 6, pl: 2, pr: 2}}>
                    <Paper elevation={6} sx={{ p: '2rem', }}>
                        <Typography sx={{ color: '#546a78', fontSize: '1.125rem', fontFamily: '"Source Sans Pro", arial, sans-serif', pt: 2, pb: 2}}>
                            You have already confirmed your subscription, please check your email account now.
                        </Typography>

                        <Typography sx={{ color: '#546a78', fontSize: '1.125rem', fontFamily: '"Source Sans Pro", arial, sans-serif', pt: 2, pb: 2}}>
                            Return to <a href='https://earthshepards.com' style={{ color: '#0c7ac0' }}>Earth Shepards</a>
                        </Typography>
                    </Paper>
                </Box>

            </Box>
        </>
    );
}

export default Confirmed;