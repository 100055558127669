import { Box, Button, ImageList, ImageListItem, Input, Table, TextField, Typography } from '@mui/material';
import react, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import Logo from '../../assets/images/logo.png';
import { checkEmailFormat } from '../../helper/utils';
import { join, register } from '../../helper/api/user';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

const SignUp = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const onSignupHandler = async () => {
        if (name === '') {
            alert('Please input name');
            return;
        } 
        if (email === '') {
            alert('Please input email');
            return;
        }
        if (email !== '' && !checkEmailFormat(email)) {
            alert('Please input valid email');
            return;
        }
        if (country === '') {
            alert('Please input Country');
            return;
        }
        if (password === '') {
            alert('Please input password');
            return;
        }
        if (password.length < 8) {
            alert('Password must be longer than 8 letters');
            return;
        }
        if (passwordConfirm !== password) {
            alert('Please input correct password confirmation');
            return;
        }
        
        const data = {
            name, email, password, passwordConfirm
        };
        
        if (await register(data, enqueueSnackbar)) {
            navigate("/active-subscription");
        } else {
            // navigate("/");
        }
    }

    return (
        <>
            {/* <Header />
            <Footer /> */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box sx={{ paddingTop: 5, paddingLeft:2.5, paddingRight: 2.5, pb: 10 }}>
                    <Box sx={{  width: { xs: '100%', sm: 550 } }}>
                        <br/>
                        <img src={Logo} alt="Logo" width={'98%'}/>
                        <br/>
                        <Typography sx={{ textAlign: 'center', color: '#3F505A', fontWeight: 'bold', fontSize: '1.75rem', fontFamily: 'Montserrat', paddingTop: 6}}>
                            Join Earth Shepards
                        </Typography>
                        <Typography sx={{ color: '#3F505A', fontSize: '1.125rem', fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}}>
                            We'll update you occasionally as the earth shepards community grows. Thank you for your patience as we start up.
                        </Typography>

                        <Typography sx={{ color: '#3F505A', fontSize: '1.125rem', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', mt: 3}}>
                            Name *
                        </Typography>
                        <TextField sx={{ width: '100%', mt: 1, mb: 1 }} value={name} onChange={(e) => {setName(e.target.value)}}/>
                        
                        <Typography sx={{ color: '#3F505A', fontSize: '1.125rem', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', mt: 1}}>
                            Email *
                        </Typography>
                        <TextField sx={{ width: '100%', mt: 1, mb: 1 }} value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                        
                        <Typography sx={{ color: '#3F505A', fontSize: '1.125rem', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', mt: 1}}>
                            Country *
                        </Typography>
                        <TextField sx={{ width: '100%', mt: 1, mb: 1 }} value={country} onChange={(e) => {setCountry(e.target.value)}}/>

                        <Typography sx={{ color: '#3F505A', fontSize: '1.125rem', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', mt: 1}}>
                            Password *
                        </Typography>
                        <TextField sx={{ width: '100%', mt: 1, mb: 1 }} type='password' value={password} onChange={(e) => {setPassword(e.target.value)}}/>
                        
                        <Typography sx={{ color: '#3F505A', fontSize: '1.125rem', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', mt: 1}}>
                            Password Confirmation *
                        </Typography>
                        <TextField sx={{ width: '100%', mt: 1, mb: 3 }} type='password' value={passwordConfirm} onChange={(e) => {setPasswordConfirm(e.target.value)}}/>

                        <Button onClick={onSignupHandler} sx={{ width: '100%', backgroundColor: '#195ACA !important', color: 'white', fontSize: '1.125rem', textTransform: 'none' }}>Join Earth Shepards</Button>
                    </Box>
                </Box>

            </Box>
        </>
    );
}

export default SignUp;