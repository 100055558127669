import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Image1 from '../../assets/images/howitworks1.png';
import Image2 from '../../assets/images/howitworks2.png';
import Image3 from '../../assets/images/howitworks3.png';
import Grid from '@mui/material/Unstable_Grid2';

function HowItWorks() {
  return (
    <Box sx={{ backgroundColor: '#91D36E' }}>
      <Container maxWidth="lg" sx={{padding: 5}}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ color: '#1a202c', fontWeight: 'bold', fontSize: '28px', fontFamily: 'Montserrat'}}>
            Help Reduce The Extreme Weather We Have All Been Experiencing, From Floods To Tornadoes To Wind And Hurricanes.
          </Typography>
        </Box>
        <Grid container spacing={2}>
            <Grid xs={12} md={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 4}}>
                  <a href='https://forms.aweber.com/form/35/1056428835.htm' alt=''><img src={Image1} alt='' width={124}/></a>
                  <Typography sx={{ color: '#1a202c', fontWeight: 'bold', fontSize: '23px', fontFamily: 'Montserrat'}}>
                    Join
                  </Typography>
                  <Typography sx={{ color: '#1a202c', fontSize: '17px', textAlign: 'center', marginTop: 2}}>
                    Sign up today and join the flock! It is fast and easy to begin.
                  </Typography>
                </Box>
            </Grid>
            <Grid xs={12} md={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 4}}>
                  <img src={Image2} alt='' width={124}/>
                  <Typography sx={{ color: '#1a202c', fontWeight: 'bold', fontSize: '23px', fontFamily: 'Montserrat'}}>
                    Shop
                  </Typography>
                  <Typography sx={{ color: '#1a202c', fontSize: '17px', textAlign: 'center', marginTop: 2}}>
                    Help reduce extreme weather by shopping at participating partners.
                  </Typography>
                </Box>
            </Grid>
            <Grid xs={12} md={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 4}}>
                  <img src={Image3} alt='' width={124}/>
                  <Typography sx={{ color: '#1a202c', fontWeight: 'bold', fontSize: '23px', fontFamily: 'Montserrat', textAlign: 'center'}}>
                    Earn LIVE Rewards
                  </Typography>
                  <Typography sx={{ color: '#1a202c', fontSize: '17px', textAlign: 'center', marginTop: 2}}>
                    Watch your balance grow as you earn more and more each day.
                  </Typography>
                </Box>
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default HowItWorks;