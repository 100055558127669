import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import LogoWhite from '../../assets/images/logo-white.png';
import Grid from '@mui/material/Unstable_Grid2';
import RegisterModal from '../RegisterModal';
import { useNavigate } from "react-router-dom";


function Intro() {

  const navigate = useNavigate();

  // const [openRegisterModal, setOpenRegisterModal] = React.useState(false);
  // const handleOpenRegisterModal = () => { };
  // const handleCloseRegisterModal = () => setOpenRegisterModal(false);

  return (
    <Box sx={{ backgroundImage: 'linear-gradient(180deg, #0A6323 0%, #0A0C1E 100%)', marginTop: 12}}>
      <Container maxWidth="lg" sx={{padding: 5}}>
        <Grid container spacing={2}>
            <Grid xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', textAlign: { xs: 'center', lg: 'left'}, alignItems: { xs: 'center'} }}>
                <Typography sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: '47px', textShadow: '2px 2px 7px #000000', fontFamily: 'Montserrat'}}>
                  Earn Your Future
                </Typography>
                <Button size='large' variant='contained' onClick={() => {window.location.href = 'https://forms.aweber.com/form/35/1056428835.htm' }} sx={{ marginTop: 4, width: 200, fontWeight: 'bold'}} >
                    SIGN UP TODAY! &nbsp;
                    <svg aria-hidden="true" class="e-font-icon-svg e-fas-arrow-alt-circle-right" style={{ fill: 'white', width: 16}} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"></path></svg>
                </Button>
            </Grid>
            <Grid xs={12} md={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={LogoWhite} alt="Logo" width={392} />
                </Box>
            </Grid>
        </Grid>
      </Container>
      {/* <RegisterModal open={openRegisterModal} handleClose={handleCloseRegisterModal} /> */}
    </Box>
  );
}
export default Intro;