import { Box, Button, ImageList, ImageListItem, Input, Paper, Table, TextField, Typography } from '@mui/material';
import react, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import EmailCheck from '../../assets/images/graphic-3d-email-check.png';
import { checkEmailFormat } from '../../helper/utils';
import { join, register } from '../../helper/api/user';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

const ActiveSubscription = () => {

    return (
        <>
            {/* <Header />
            <Footer /> */}
            <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#eeeff3', height: '100vh'}}>
                <Box sx={{ width: {xs: '100%', lg: '1092px'}, mt: 11, pl: 2, pr: 2}}>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Box sx={{  width: { xs: '100%', lg: '60%' } }}>
                            <Typography sx={{ fontWeight: 700, fontSize: '2rem', fontFamily: '"Heebo", arial, sans-serif'}}>
                                You're Almost Done.
                                <br/>
                                Activate Your Subscription!
                            </Typography>
                            <Typography sx={{ fontSize: '1.125rem', fontFamily: '"Heebo", arial, sans-serif', pt: 2, pb: 2}}>
                                We've just sent you an email with a <b>confirm link.</b>
                            </Typography>
                            <Typography sx={{ fontSize: '14px', fontFamily: '"Heebo", arial, sans-serif', pb: 2}}>
                                In order to activate your subscription, check your email and click on the link in that email. You will not receive your subscription until you <b>click that link to activate it.</b>
                            </Typography>

                            <Button onClick={() => { window.location.href = 'https://mail.google.com/mail/#search/from%3Aservice%40earthshepards.com+in%3Aanywhere' }} sx={{ backgroundColor: '#246be8 !important', color: 'white', fontWeight: 600, fontSize: '1rem', fontFamily: '"Heebo", arial, sans-serif', textTransform: 'none', pl: 2, pr: 2 }}>Open Gmail</Button>
                        </Box>
                        <Box sx={{ display: {xs: 'none', lg: 'block'}, width: '40%' }}>
                            <img src={EmailCheck} alt="Logo" width={'98%'}/>
                        </Box>
                    </Box>

                    <Paper elevation={6} sx={{ p: '1.5rem', textAlign: 'center', mt: 5}}>If you don't see that email in your inbox within 12 hours, please reach out to the owner of the mailing list for more information.</Paper>
                </Box>

            </Box>
        </>
    );
}

export default ActiveSubscription;