import axios from 'axios';

export const BACKEND_API_URL = 'https://shearscan.com/shearapp/api/';
// export const BACKEND_API_URL = 'http://localhost:5050/shearapp/api/';

export const register = async (data, enqueueSnackbar) => {
    try {
        const res = await axios.post(BACKEND_API_URL + 'auth/signup', data);
        console.log(res);
        if (res.status === 201) {

        } else {
            enqueueSnackbar('Successfully Registered!!!', { variant: 'success' });
        }
        return true;
    } catch (error) {
        console.log(error);
        if (error.response.data.message == 'E11000 duplicate key error') {
            window.location.href = '/subscribed';
        } else {
            enqueueSnackbar('Register Failed: ' + error.response.data.message, { variant: 'error' });
        }
        return false;
    }
}

export const sendMails = async (data, enqueueSnackbar) => {
    try {
        await axios.post(`${BACKEND_API_URL}user/sendmails`, data);
        enqueueSnackbar('Invitation Sent Successfully!!!', { variant: 'success' });
    } catch(error) {
        console.log(error);
        enqueueSnackbar('Sending Invitation Failed!!!', { variant: 'error' });
    }
}