import { Box, Button, ImageList, ImageListItem, Input, Table, TextField } from '@mui/material';
import react, { useEffect, useState } from 'react';
import Header from '../components/header';
import Intro from '../components/Intro';
import HowItWorks from '../components/HowItWorks';
import OurStory from '../components/OurStory';
import Footer from '../components/Footer';
import SignUpToday from '../components/SignUpToday';

const Page = () => {
    return (
        <>
            <Header />
            <Intro />
            <HowItWorks />
            <OurStory />
            <SignUpToday />
            <Footer />
        </>
    );
}

export default Page;