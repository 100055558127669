import { Box, Button, ImageList, ImageListItem, Input, Paper, Table, TextField, Typography } from '@mui/material';
import react, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import EmailCheck from '../../assets/images/graphic-3d-email-check.png';
import { checkEmailFormat } from '../../helper/utils';
import { join, register } from '../../helper/api/user';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

const Subscribed = () => {

    return (
        <>
            {/* <Header />
            <Footer /> */}
            <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#eff6ff', height: '100vh'}}>
                <Box sx={{ width: {xs: '100%', lg: '720px'}, mt: 6, pl: 2, pr: 2}}>
                    <Paper elevation={6} sx={{ p: '2rem', }}>
                        <Typography sx={{ color: '#546a78', fontWeight: 700, fontSize: '1.65831rem', fontFamily: '"Source Sans Pro", arial, sans-serif'}}>
                            You're subscribed!
                        </Typography>
                        <Typography sx={{ color: '#546a78', fontSize: '1.125rem', fontFamily: '"Source Sans Pro", arial, sans-serif', pt: 2, pb: 2}}>
                            Thank you for signing up! You're on the list and will get emails from us soon.
                        </Typography>

                        <a href='/signup' style={{ color: '#0c7ac0', fontSize: '14px'}}>Return to the last page</a>
                    </Paper>
                </Box>

            </Box>
        </>
    );
}

export default Subscribed;