import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Input, TextField } from '@mui/material';
import { register } from '../../helper/api/user';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 380,
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 6,
  textAlign: 'center'
};

export default function RegisterModal({open, handleClose}) {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordConfirm, setPasswordConfirm] = React.useState('');

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const registerHandle = async () => {
        
        const data = {
            name, email, password, passwordConfirm
        };

        if(await register(data, enqueueSnackbar)) {
            handleClose();
            navigate("/thank-you");
        }
    }

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Typography id="modal-modal-title" sx={{ fontSize: 30, fontWeight: 'bold', fontFamily: 'Montserrat' }} >
                Signup for you free account
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 14, fontWeight: 'bold'}}>
                Already have an account? &nbsp;
                <a href='https://app.earthshepards.com' style={{ textDecoration: 'none', fontSize: 14, fontWeight: 'bold', color: '#007fcf' }}>
                    Login
                </a>
            </Typography>
            <TextField value={name} onChange={(e) => {setName(e.target.value)}} placeholder='Full Name' variant="outlined" sx={{ mt: 4, width: { xs: '90%', md:'70%'}}} />
            <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder='Email Address' variant="outlined" sx={{ mt: 4, width: { xs: '90%', md:'70%'}}} />
            <TextField value={password} onChange={(e) => {setPassword(e.target.value)}} type='password' placeholder='Password' variant="outlined" sx={{ mt: 4, width: { xs: '90%', md:'70%'}}} />
            <TextField value={passwordConfirm} onChange={(e) => {setPasswordConfirm(e.target.value)}} type='password' placeholder='Confirm Password' variant="outlined" sx={{ mt: 4, width: { xs: '90%', md:'70%'}}} />
            <Box sx={{ mt: 4}}>
                <Button variant='contained' onClick={registerHandle}>Register</Button>
            </Box>
            <Typography id="modal-modal-description" sx={{ mt: 4, fontSize: 13}}>
                By registering you agree to the &nbsp;
                <a href='#' style={{ textDecoration: 'none', fontSize: 13, color: '#007fcf' }}>
                    Terms and Conditions
                </a>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1, fontSize: 13 }}>
                and have read the &nbsp;
                <a href='#' style={{ textDecoration: 'none', fontSize: 13, color: '#007fcf' }}>
                    Privacy Policy
                </a>
            </Typography>
        </Box>
    </Modal>
  );
}