import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import StarIcon from '@mui/icons-material/Star';
import ShearLogo from '../../assets/images/Shear-Logo.png';
import { Divider } from '@mui/material';

function OurStory() {
  return (
    <>
      <Box id='our-story' sx={{ backgroundColor: '#6cb2fa', scrollMarginTop: '98px' }}>
        <Box sx={{ textAlign: 'center', padding: 5 }}>
          <Typography sx={{ color: '#1a202c', fontWeight: 'bold', fontSize: '28px', fontFamily: 'Montserrat'}}>
              Our Story
          </Typography>
        </Box>
      </Box>
      <Box>
        <Container maxWidth="lg" sx={{padding: 5}}>
          <Grid container spacing={2}>
            <Grid xs={12} md={9}>
              <Box sx={{ display: 'flex', padding: 2}}>
                <StarIcon sx={{ color: '#e9b92e'}}/>
                <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 2}}>
                  We are introducing a new type of Reward, a LIVE Reward
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', padding: 2}}>
                <StarIcon sx={{ color: '#e9b92e'}}/>
                <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 2}}>
                  All rewards are DEAD; they do nothing for you except allow you to exchange (if the rules stay the same!)
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', padding: 2}}>
                <StarIcon sx={{ color: '#e9b92e'}}/>
                <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 2}}>
                  Shear Rewards allows you to earn interest, use as collateral, and purchase stuff vetted to be better for the earth
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', padding: 2}}>
                <StarIcon sx={{ color: '#e9b92e'}}/>
                <Typography sx={{ color: '#1a202c', fontSize: '17px', paddingLeft: 2}}>
                  They can go up and down in value and are traded on public markets
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img src={ShearLogo} alt="Logo" width={150} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider />
    </>
  );
}
export default OurStory;