import { Box, Button, ImageList, ImageListItem, Input, Table, TextField } from '@mui/material';
import react, { useEffect, useState } from 'react';
import Header from '../../components/header';
import ThankYouAndShare from '../../components/ThankYouAndShare';
import Footer from '../../components/Footer';

const ThankYou = () => {
    return (
        <>
            <Header />
            <ThankYouAndShare />
            <Footer />
        </>
    );
}

export default ThankYou;