import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import StarIcon from '@mui/icons-material/Star';
import ShearLogo from '../../assets/images/Shear-Logo.png';
import { Button, Divider } from '@mui/material';
import RegisterModal from '../RegisterModal';
import { useNavigate } from "react-router-dom";

function SignUpToday() {

  const navigate = useNavigate();

  // const [openRegisterModal, setOpenRegisterModal] = React.useState(false);
  // const handleOpenRegisterModal = () => setOpenRegisterModal(true);
  // const handleCloseRegisterModal = () => setOpenRegisterModal(false);

  return (
    <>
      <Box sx={{  }}>
        <Box sx={{ textAlign: 'center', padding: 5 }}>
          <Typography sx={{ color: '#1a202c', fontWeight: 'bold', fontSize: '28px', fontFamily: 'Montserrat'}}>
            Sign Up Today!
          </Typography>
          <Button size='large' variant='contained' onClick={() => {window.location.href = 'https://forms.aweber.com/form/35/1056428835.htm'}} sx={{ marginTop: 1, width: 200, fontWeight: 'bold'}} >
            SIGN UP TODAY! &nbsp;
            <svg aria-hidden="true" class="e-font-icon-svg e-fas-arrow-alt-circle-right" style={{ fill: 'white', width: 16}} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"></path></svg>
          </Button>
        </Box>
      </Box>
      {/* <RegisterModal open={openRegisterModal} handleClose={handleCloseRegisterModal} /> */}
    </>
  );
}
export default SignUpToday;