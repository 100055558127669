import * as React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { SnackbarProvider, useSnackbar } from 'notistack';

import './App.css';
import Page from './pages';
import ThankYou from './pages/ThankYou';
import SignUp from './pages/SignUp';
import ActiveSubscription from './pages/SignUp/activeSubscription';
import Subscribed from './pages/SignUp/subscribed';
import Confirmed from './pages/SignUp/confirmed';

function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}>
      <CssBaseline />
      <React.Suspense fallback={<React.Fragment />}>
        <Routes>
          <Route path="/" element={<Page />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/active-subscription" element={<ActiveSubscription />} />
          <Route path="/subscribed" element={<Subscribed />} />
          <Route path="/confirmed" element={<Confirmed />} />
        </Routes>
      </React.Suspense>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
