import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import StarIcon from '@mui/icons-material/Star';
import ShearLogo from '../../assets/images/Shear-Logo.png';
import { Button, Divider } from '@mui/material';
import DiscordIcon from '../../assets/images/discord-icon.png';
import TelegramIcon from '../../assets/images/telegram-icon.png';
import LinkedInIcon from '../../assets/images/linkedin-icon.png';
import InstagramIcon from '../../assets/images/instagram-icon.png';

function OurStory() {
  return (
    <>
      <Box sx={{ backgroundColor: '#91D36E' }}>
        <Container maxWidth="lg" sx={{padding: 4}}>
          <Box sx={{ textAlign: 'center' }}>
            <a href='#' style={{ textDecoration: 'none' }}>Legal Stuff</a>
            {/* <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Privacy Policy</a></li>
            <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Cookie Policy</a></li>
            <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Disclaimer</a></li>
            <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Security Policy</a></li>
            <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Terms & Conditions</a></li> */}
          </Box>
          {/* <Box sx={{ textAlign: 'center', display: { xs: 'block', sm: 'none'} }}>
            <ul style={{ display: 'flex', justifyContent: 'space-between', padding: 0}}>
              <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Privacy Policy</a></li>
              <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Cookie Policy</a></li>
              <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Disclaimer</a></li>
            </ul>
            <ul style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20}}>
              <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Security Policy</a></li>
              <li style={{ display: 'inline'}}><a href='#' style={{ textDecoration: 'none' }}>Terms & Conditions</a></li>
            </ul>
          </Box> */}
        </Container>
      </Box>
      <Box sx={{ padding: 4}}>
        <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2}}>
          <a href='https://t.me/+7JeaBE0RMB41MDIx' target='_blank'><img src={TelegramIcon} alt="telegram" width={30}/></a>
          &nbsp;&nbsp;&nbsp;
          <a href='https://discord.gg/M5Exb5vV' target='_blank'><img src={DiscordIcon} alt="discord" width={30}/></a>
          &nbsp;&nbsp;&nbsp;
          <a href='https://www.linkedin.com/company/earthshepards/about/' target='_blank'><img src={LinkedInIcon} alt="linkedin" width={28}/></a>
          &nbsp;&nbsp;&nbsp;
          <a href='https://www.instagram.com/earth.shepards/' target='_blank'><img src={InstagramIcon} alt="instagram" width={30}/></a>
        </Box>
        <Typography sx={{ color: '#1a202c', textAlign: 'center', fontSize: '14px'}}>
          © 2024 Earth Shepards - All rights reserved.
        </Typography>
      </Box>
      <Divider />
    </>
  );
}
export default OurStory;